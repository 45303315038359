import * as React from "react";
import * as styles from "./privacy-policy.module.css";

import { Button, IconButton } from "@chakra-ui/react";
import { FunctionComponent, useCallback, useState } from "react";

import { HamburgerIcon } from "@chakra-ui/icons";
import Layout from "../components/Base/Layout";
import NavigationMobile from "../components/Header/navigation-mobile";
import PortalDrawer from "../components/Header/portal-drawer";
import { SEO } from "../components/Base/Seo";
import { navigate } from "gatsby";

const PrivacyPolicy: FunctionComponent = () => {


  return (
    <Layout>
      <div className={styles.privacyPolicy}>

        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.subtitleLarge}>
              <b className={styles.display}>CDI HEALTH</b>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>Privacy Policy</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Last updated: June 13, 2022
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  This Privacy Policy describes Our policies and procedures on
                  the collection, use and disclosure of Your information when
                  You use the Service and tells You about Your privacy rights
                  and how the law protects You.
                </div>
              </div>
              <div className={styles.p2}>
                <div className={styles.caption}>
                  {`We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. `}

                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                Interpretation and Definitions
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Interpretation</b>
                  </p>
                  <p className={styles.interpretation}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      The words of which the initial letter is capitalized have
                      meanings defined under the following conditions. The
                      following definitions shall have the same meaning
                      regardless of whether they appear in singular or in
                      plural.
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Definitions</b>
                  </p>
                  <p className={styles.interpretation}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>For the purposes of this Privacy Policy:</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Account `}</b>
                    <span>
                      means a unique account created for You to access our
                      Service or parts of our Service.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Company `}</b>
                    <span>
                      (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Cdihealth.net, Address: 5800 Ambler Drive, Suite 210, Mississauga, Ontario L4W 4J4.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Cookies `}</b>
                    <span>
                      are small files that are placed on Your computer, mobile
                      device or any other device by a website, containing the
                      details of Your browsing history on that website among its
                      many uses.
                    </span>
                  </ul>
                </div>
              </div>
            </div>

            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Device `}</b>
                    <span>
                      means any device that can access the Service such as a
                      computer, a cellphone or a digital tablet.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Personal Data `}</b>
                    <span>
                      is any information that relates to an identified or
                      identifiable individual.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Service `}</b>
                    <span>refers to the Website.</span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Service Provider `}</b>
                    <span>
                      means any natural or legal person who processes the data
                      on behalf of the Company. It refers to third-party
                      companies or individuals employed by the Company to
                      facilitate the Service, to provide the Service on behalf
                      of the Company, to perform services related to the Service
                      or to assist the Company in analyzing how the Service is
                      used.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Usage Data `}</b>
                    <span>
                      refers to data collected automatically, either generated
                      by the use of the Service or from the Service
                      infrastructure itself (for example, the duration of a page
                      visit).
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Website `}</b>
                    <span>
                      refers to cdihealth.net, accessible from [CDI Health](CDI
                      Health)
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`You `}</b>
                    <span>
                      means the individual accessing or using the Service, or
                      the company, or other legal entity on behalf of which such
                      individual is accessing or using the Service, as
                      applicable.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                Collecting and Using Your Personal Data
              </div>
            </div>
            <div className={styles.subtitle3}>
              <div className={styles.display1}>Types of Data Collected</div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Personal Data</b>
                  </p>
                  <p className={styles.blankLine2}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.interpretation}>
                    <span>
                      While using Our Service, We may ask You to provide Us with
                      certain personally identifiable information that can be
                      used to contact or identify You. Personally identifiable
                      information may include, but is not limited to:
                    </span>
                  </p>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <li className={styles.emailAddress}>
                      <span>Email address</span>
                    </li>
                    <li className={styles.emailAddress}>
                      <span>First name and last name</span>
                    </li>
                    <li>
                      <span>Usage Data</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Do we receive any information from third parties?</b>
                  </p>
                  <p className={styles.blankLine2}>
                    <span>{` `}</span>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      We do not receive any information from third parties.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption18}>
                  <p className={styles.interpretation}>
                    <b>How do we process your information?</b>
                  </p>
                  <p className={styles.blankLine2}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      We process your information to provide, improve, and
                      administer our Services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent. We process your information only when we
                      have a valid legal reason to do so.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Usage Data</b>
                  </p>
                  <p className={styles.blankLine2}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      Usage Data is collected automatically when using the
                      Service.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Usage Data may include information such as Your Device's
                  Internet Protocol address (e.g. IP address), browser type,
                  browser version, the pages of our Service that You visit, the
                  time and date of Your visit, the time spent on those pages,
                  unique device identifiers and other diagnostic data.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  When You access the Service by or through a mobile device, We
                  may collect certain information automatically, including, but
                  not limited to, the type of mobile device You use, Your mobile
                  device unique ID, the IP address of Your mobile device, Your
                  mobile operating system, the type of mobile Internet browser
                  You use, unique device identifiers and other diagnostic data.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We may also collect information that Your browser sends
                  whenever You visit our Service or when You access the Service
                  by or through a mobile device.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Tracking Technologies and Cookies</b>
                  </p>
                  <p className={styles.blankLine2}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      We use Cookies and similar tracking technologies to track
                      the activity on Our Service and store certain information.
                      Tracking technologies used are beacons, tags, and scripts
                      to collect and track information and to improve and
                      analyze Our Service. The technologies We use may include:
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`Cookies or Browser Cookies. `}</b>
                    <span>
                      A cookie is a small file placed on Your Device. You can
                      instruct Your browser to refuse all Cookies or to indicate
                      when a Cookie is being sent. However, if You do not accept
                      Cookies, You may not be able to use some parts of our
                      Service. Unless you have adjusted Your browser setting so
                      that it will refuse Cookies, our Service may use Cookies.
                    </span>
                  </ul>
                </div>
              </div>
            </div>

            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Web Beacons. `}</b>
                    <span>
                      Certain sections of our Service and our emails may contain
                      small electronic files known as web beacons (also referred
                      to as clear gifs, pixel tags, and single-pixel gifs) that
                      permit the Company, for example, to count users who have
                      visited those pages or opened an email and for other
                      related website statistics (for example, recording the
                      popularity of a certain section and verifying system and
                      server integrity).
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody24}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    {`Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies on `}
                    <a
                      className={styles.termsFeedPrivacyPolicyGene}
                      href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
                      target="_blank"
                    >
                      <span className={styles.termsFeedPrivacy}>
                        TermsFeed website
                      </span>
                    </a>{" "}
                    article.
                  </p>
                  <p className={styles.interpretation}>&nbsp;</p>
                  <p className={styles.interpretation}>
                    We use both Session and Persistent Cookies for the purposes
                    set out below:
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.textbody14}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <p className={styles.interpretation}>
                      <b>Necessary / Essential Cookies</b>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>Type: Session Cookies</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>Administered by: Us</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.theWordsOfWhichTheInitial}>
                      <span>
                        Purpose: These Cookies are essential to provide You with
                        services available through the Website and to enable You
                        to use some of its features. They help to authenticate
                        users and prevent fraudulent use of user accounts.
                        Without these Cookies, the services that You have asked
                        for cannot be provided, and We only use these Cookies to
                        provide You with those services.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.textbody14}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <p className={styles.interpretation}>
                      <b>Cookies Policy / Notice Acceptance Cookies</b>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>Type: Persistent Cookies</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>Administered by: Us</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.theWordsOfWhichTheInitial}>
                      <span>
                        Purpose: These Cookies identify if users have accepted
                        the use of cookies on the Website.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.textbody14}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <p className={styles.interpretation}>
                      <b>Functionality Cookies</b>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>Type: Persistent Cookies</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>Administered by: Us</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.theWordsOfWhichTheInitial}>
                      <span>
                        Purpose: These Cookies allow us to remember choices You
                        make when You use the Website, such as remembering your
                        login details or language preference. The purpose of
                        these Cookies is to provide You with a more personal
                        experience and to avoid You having to re-enter your
                        preferences every time You use the Website.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.p}>
                <div className={styles.caption}>
                  For more information about the cookies we use and your choices
                  regarding cookies, please visit our Cookies Policy or the
                  Cookies section of our Privacy Policy.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Use of Your Personal Data</b>
                  </p>
                  <p className={styles.blankLine2}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      The Company may use Personal Data for the following
                      purposes:
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`To provide and maintain our Service, `}</b>
                    <span>including to monitor the usage of our Service.</span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`To manage your account: `}</b>
                    <span>
                      to manage Your registration as a user of the Service. The
                      Personal Data You provide can give You access to different
                      functionalities of the Service that are available to You
                      as a registered user.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`For the performance of a contract: `}</b>
                    <span>
                      the development, compliance and undertaking of the
                      purchase contract for the products, items or services You
                      have purchased or of any other contract with Us through
                      the Service.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`To contact You: `}</b>
                    <span>
                      To contact You by email, telephone calls, SMS, or other
                      equivalent forms of electronic communication, such as a
                      mobile application's push notifications regarding updates
                      or informative communications related to the
                      functionalities, products or contracted services,
                      including the security updates, when necessary or
                      reasonable for their implementation.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`To provide You `}</b>
                    <span>
                      with news, special offers and general information about
                      other goods, services and events which we offer that are
                      similar to those that you have already purchased or
                      enquired about unless You have opted not to receive such
                      information.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`To manage Your requests: `}</b>
                    <span>To attend and manage Your requests to Us.</span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`For business transfers: `}</b>
                    <span>
                      We may use Your information to evaluate or conduct a
                      merger, divestiture, restructuring, reorganisation,
                      dissolution, or other sale or transfer of some or all of
                      Our assets, whether as a going concern or as part of
                      bankruptcy, liquidation, or similar proceeding, in which
                      Personal Data held by Us about our Service users is among
                      the assets transferred.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`For other purposes: `}</b>
                    <span>
                      We may use Your information for other purposes, such as
                      data analysis, identifying usage trends, determining the
                      effectiveness of our promotional campaigns and to evaluate
                      and improve our Service, products, services, marketing and
                      your experience.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We may share Your personal information in the following
                  situations:
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`With Service Providers: `}</b>
                    <span>
                      We may share Your personal information with Service
                      Providers to monitor and analyze the use of our Service,
                      to contact You.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`For business transfers: `}</b>
                    <span>
                      We may share or transfer Your personal information in
                      connection with, or during negotiations of, any merger,
                      sale of Company assets, financing, or acquisition of all
                      or a portion of Our business to another company.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`With Affiliates: `}</b>
                    <span>
                      We may share Your information with Our affiliates, in
                      which case we will require those affiliates to honor this
                      Privacy Policy. Affiliates include Our parent company and
                      any other subsidiaries, joint venture partners or other
                      companies that We control or that are under common control
                      with Us.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b
                      className={styles.account}
                    >{`With business partners: `}</b>
                    <span>
                      We may share Your information with Our business partners
                      to offer You certain products, services or promotions.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`With other users: `}</b>
                    <span>
                      when You share personal information or otherwise interact
                      in the public areas with other users, such information may
                      be viewed by all users and may be publicly distributed
                      outside.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`With Your consent: `}</b>
                    <span>
                      We may disclose Your personal information for any other
                      purpose with Your consent.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                Retention of Your Personal Data
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The Company will retain Your Personal Data only for as long as
                  is necessary for the purposes set out in this Privacy Policy.
                  We will retain and use Your Personal Data to the extent
                  necessary to comply with our legal obligations (for example,
                  if we are required to retain your data to comply with
                  applicable laws), resolve disputes, and enforce our legal
                  agreements and policies.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The Company will also retain Usage Data for internal analysis
                  purposes. Usage Data is generally retained for a shorter
                  period of time, except when this data is used to strengthen
                  the security or to improve the functionality of Our Service,
                  or We are legally obligated to retain this data for longer
                  time periods.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <b className={styles.caption}>Transfer of Your Personal Data</b>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Your information, including Personal Data, is processed at the
                  Company's operating offices and in any other places where the
                  parties involved in the processing are located. It means that
                  this information may be transferred to — and maintained on —
                  computers located outside of Your state, province, country or
                  other governmental jurisdiction where the data protection laws
                  may differ than those from Your jurisdiction.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Your consent to this Privacy Policy followed by Your
                  submission of such information represents Your agreement to
                  that transfer.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  The Company will take all steps reasonably necessary to ensure
                  that Your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of Your data
                  and other personal information.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <b className={styles.caption}>
                  Disclosure of Your Personal Data
                </b>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>Business Transactions</p>
                  <p className={styles.interpretation}>&nbsp;</p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>Law enforcement</p>
                  <p className={styles.interpretation}>&nbsp;</p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <b className={styles.caption}>Other legal requirements</b>
              </div>
            </div>
            <div className={styles.textbody55}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                  </p>
                  <p className={styles.interpretation}>&nbsp;</p>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <li className={styles.emailAddress}>
                      Comply with a legal obligation
                    </li>
                    <li className={styles.emailAddress}>
                      Protect and defend the rights or property of the Company
                    </li>
                    <li className={styles.emailAddress}>
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </li>
                    <li className={styles.emailAddress}>
                      Protect the personal safety of Users of the Service or the
                      public
                    </li>
                    <li>Protect against legal liability</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <b className={styles.caption}>Children's Privacy</b>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Our Service does not address anyone under the age of 18. We do
                  not knowingly collect personally identifiable information from
                  anyone under the age of 18. If You are a parent or guardian
                  and You are aware that Your child has provided Us with
                  Personal Data, please contact Us. If We become aware that We
                  have collected Personal Data from anyone under the age of 18
                  without verification of parental consent, We take steps to
                  remove that information from Our servers.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <b className={styles.caption}>Links to Other Websites</b>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  Our Service may contain links to other websites that are not
                  operated by Us. If You click on a third party link, You will
                  be directed to that third party's site. We strongly advise You
                  to review the Privacy Policy of every site You visit.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party
                  sites or services.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <b className={styles.caption}>Changes to this Privacy Policy</b>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We may update Our Privacy Policy from time to time. We will
                  notify You of any changes by posting the new Privacy Policy on
                  this page.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  We will let You know via email and/or a prominent notice on
                  Our Service, prior to the change becoming effective and update
                  the "Last updated" date at the top of this Privacy Policy.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <b className={styles.caption}>Contact Us</b>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  If you have any questions about this Privacy Policy, You can
                  contact us by email: info@cdihealth.net
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </Layout>
  );
};

export default PrivacyPolicy;

export const Head = () => (
  <SEO title="CDI Health" description="Privacy Policy" />
)
